<template>
    <div class="w-1200 mt-5 mb-5">
        <el-steps :active="2" simple>
            <el-step title="选择商品" icon="iconfont icon-gouwuche"></el-step>
            <el-step title="填写订单信息" icon="el-icon-edit"></el-step>
            <el-step title="提交订单" icon="el-icon-upload"></el-step>
        </el-steps>
        <div class="address-box">
            <div class="step-tit d-f justify-between">
                <h3>收货人信息</h3>
                <router-link class="option" to="/userCenter/address">管理收货人地址</router-link>
            </div>
            <div class="address-list mt-2 d-f justify-start">
                <div class="address-item" v-for="(item,index) in addressList"
                     :class="checkAddress==index?'checked':''"
                     @click="changeAddress(index,item)" :key="item.id">
                    <div class="name">{{item.contact_name}}</div>
                    <div class="phone">{{item.contact_phone}}</div>
                    <div class="address">{{item.province}}{{item.city}} {{item.district}} {{item.address}}</div>
                    <div class="default" v-if="item.is_default == 1">默认地址</div>
                    <i class="checked-icon"></i>
                </div>
            </div>
        </div>
        <div class="goods-box">
            <div class="step-tit">
                <h3>商品信息</h3>
            </div>
            <el-table class="mt-2"
                      ref="multipleTable" border stripe
                      :data="goodsListInfo.item"
                      tooltip-effect="dark"
                      style="width: 100%">
                <el-table-column type="index" width="40" align="center"/>
                <el-table-column label="图片" width="80" align="center">
                    <template slot-scope="scope">
                        <el-popover
                                placement="right"
                                trigger="hover"
                        >
                            <img
                                    slot="reference"
                                    style="width: 50px; height: 50px"
                                    v-lazy="scope.row.img"
                            />
                            <img
                                    style="width: 220px; height: 220px"
                                    :src="scope.row.img"
                            />
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="商品名称" prop="product_name"/>
                <el-table-column label="单价" prop="price" width="90" header-align="center" align="right">
                    <template slot-scope="scope">
                        <div>￥{{ scope.row.price }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="规格" prop="sku" width="130"/>
                <el-table-column label="数量" prop="amount" width="100"/>
                <el-table-column label="小计" width="90" header-align="center" align="right">
                    <template slot-scope="scope">
                        <div>￥{{scope.row.total_price}}</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="coupon-box">
            <div class="step-tit clearfix">
                <h3 class="f-l">优惠券</h3>
                <div class="clear-discount f-r" @click="clearCoupon">取消使用优惠券</div>
            </div>
            <div class="coupon-content">
                <div class="coupon-text">优惠券</div>
                <div class="coupon-info" v-if="chooseCouponInfo.id" @click="toChooseCoupon">
                    满
                    <span>{{chooseCouponInfo.fullMoney}}</span>
                    减
                    <span>{{chooseCouponInfo.couponMoney}}</span>
                </div>
                <div class="coupon-info" v-else @click="toChooseCoupon">选择优惠券 ></div>
            </div>
        </div>
        <div class="coupon-box">
            <div class="step-tit clearfix">
                <h3 class="f-l">红包</h3>
                <div class="clear-discount f-r"  @click="clearRedEnvelopes">取消使用红包</div>
            </div>
            <div class="coupon-content">
                <div class="coupon-text">红包</div>
                <div class="coupon-info" v-if="chooseRedEnvelopeInfo.id" @click="toChooseRedEnvelope">
                    减
                    <span>{{chooseRedEnvelopeInfo.red_envelopes_money}}</span>
                </div>
                <div class="coupon-info" v-else @click="toChooseRedEnvelope">选择红包 ></div>
            </div>
        </div>
        <div class="bg-f1 mt-2 d-f justify-between cart-option">
            <div class="common">总价(不含运费)：
                <span class="total-price text-main-color">￥{{orderTotalPrice}}</span>
            </div>
            <el-button type="danger" @click="submitOrder">提交订单</el-button>
        </div>

        <el-dialog title="选择优惠券" :visible.sync="chooseCouponDialogVisible" width="920px">
            <el-alert
                    title="提示"
                    type="warning"
                    :closable="false"
                    description="选择您要使用的优惠券，灰色是不可使用标准的优惠券，黄色的可以使用的优惠券"
                    show-icon>
            </el-alert>
            <div class="coupon-list">
                <div class="coupon-item" :class="item.couponStatus?'':'not-user'"
                     v-for="item in myCouponList" :key="item.id" @click="submitChooseCoupon(item)">
                    <div class="coupon-bg">
                        <div class="coupon-white"></div>
                        <div class="coupon-circular"></div>
                    </div>
                    <div class="coupon-info">
                        <div class="coupon-value">￥<span>{{item.couponMoney}}</span></div>
                        <div class="coupon-full-money">
                            <div class="full-money">满{{item.fullMoney}}可用</div>
                            <div class="coupon-name">{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 分页区域 -->
            <el-pagination
                    :current-page="myCouponQueryInfo.page"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="myCouponQueryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="myCouponTotal"
                    @size-change="myCouponSizeChange"
                    @current-change="myCouponCurrentChange"
            />
        </el-dialog>

        <el-dialog title="选择红包" :visible.sync="chooseRedEnvelopeDialogVisible" width="920px">
            <el-alert
                    title="提示"
                    type="warning"
                    :closable="false"
                    description="选择您要使用的红包"
                    show-icon>
            </el-alert>
            <div class="coupon-list">
                <div v-for="item in redEnvelopeList" :key="item.id" @click="chooseRedEnvelope(item)">
                    <div>{{item.red_envelopes_money}}</div>
                </div>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import {
  getUserReceivingAddressUrl, createOrderAddViewByCartUrl, createOrderAddViewUrl,
  createOrderAddViewBySeckillUrl, getMyCouponListUrl, getMyRedEnvelopeListUrl,createOrderAddViewByBoostUrl
} from '@/api/index'

export default {
  name: 'EditOrderInfo',
  data() {
    return {
      addressList: [],
      goodsListInfo: {
        item: []
      },
      // cart是从购物车进来的，goods是从商品详情页进来的，order是从未付款订单页面进来的
      pageType: '',
      checkAddress: 0,
      // 购物车创建订单
      cartOrderHttpObj: {
        addressId: '',
        receiveId: 0,
        envelopesReceiveId:'',
        cartId: []
      },
      // 商品详情页直接购买创建订单
      directBuyHttpObj: {
        addressId: '',
        receiveId: 0,
        envelopesReceiveId:'',
        sku: []
      },
      // 选择的优惠券信息
      chooseCouponInfo: {},
      myCouponQueryInfo: {
        pageSize: 12,
        page: 1,
        field: '',
        desc: '',
      },
      myCouponList: [],
      myCouponTotal: 0,
      chooseCouponDialogVisible: false,

      // 选择的红包信息
      chooseRedEnvelopeInfo: {},
      redEnvelopeList: [],
      chooseRedEnvelopeDialogVisible: false
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        this.$router.push('/login')
        return false
      }
      this.getGoodsList()
      this.getAddressList()
    },
    // 获取地址列表
    async getAddressList() {
      const {data: res} = await this.$http.get(getUserReceivingAddressUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.addressList = res.data
      this.addressList.forEach((item, index) => {
        let defaultAddress = []
        if (item.is_default === 1) {
          this.cartOrderHttpObj.addressId = item.id
          this.directBuyHttpObj.addressId = item.id
          defaultAddress = this.addressList.splice(index, 1)
        }
        this.addressList = [...defaultAddress, ...this.addressList]
      })
    },
    // 获取商品列表
    async getGoodsList() {
      if (this.$route.query.type != undefined) {
        this.pageTyped = this.$route.query.type
      } else {
        this.$message.error('订单异常，将自动返回购物车页面')
        this.$router.push({path: '/cart'})
        return false
      }
      switch (this.pageTyped) {
        case 'cart':
          await this.cartGoodsList()
          await this.getCouponList()
          await this.getRedEnvelopeList()
          break
        case 'goods':
          await this.directBuyGoodsList()
          await this.getCouponList()
          await this.getRedEnvelopeList()
          break
        case 'order':
          break
        case 'seckill':
          await this.seckillBuyGoodsList()
          await this.getCouponList()
          await this.getRedEnvelopeList()
          break
        case 'boost':
          await this.boostBuyGoodsList()
          await this.getCouponList()
          await this.getRedEnvelopeList()
          break
        default:
          this.$message.error('订单异常，将自动返回购物车页面')
          this.$router.push({path: '/cart'})
          break
      }
    },
    // 从秒杀商品详情页进入获取订单数据
    async seckillBuyGoodsList() {
      let vuexGoodsInfo = this.$store.state.goodsDirectBuyHttpObj
      if (JSON.stringify(vuexGoodsInfo) == '{}') {
        this.$message.error('订单异常，将自动返回商品详情页')
        this.$router.go(-1)
        return false
      }
      this.directBuyHttpObj.sku = vuexGoodsInfo.sku
      const {data: res} = await this.$http.post(createOrderAddViewBySeckillUrl, vuexGoodsInfo)
      if (res.code !== 200) {
        this.$router.go(-1)
        return this.$message.error(res.msg)
      }
      this.goodsListInfo = res.data
      this.goodsListInfo.item.forEach(items => {
        items.sku = []
        for (let i in items.product_specs) {
          items.sku.push(items.product_specs[i])
        }
        items.sku = items.sku.join('，')
      })
    },
    // 从订单页面进入获取订单数据
    async cartGoodsList() {
      let vuexGoodsInfo = this.$store.state.selectCartGoodsInfo
      // 如果vuex中没有订单信息，返回购物车页面
      if (vuexGoodsInfo.length <= 0) {
        this.$message.error('订单异常，将自动返回购物车页面')
        this.$router.push({path: '/cart'})
        return false
      }
      vuexGoodsInfo.forEach(item => {
        this.cartOrderHttpObj.cartId.push(item.id)
      })
      const {data: res} = await this.$http.post(createOrderAddViewByCartUrl, {cartId: this.cartOrderHttpObj.cartId})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.goodsListInfo = res.data
      this.goodsListInfo.item.forEach(items => {
        items.sku = []
        for (let i in items.product_specs) {
          items.sku.push(items.product_specs[i])
        }
        items.sku = items.sku.join('，')
      })
    },
    // 从商品详情页进入获取订单数据
    async directBuyGoodsList() {
      let vuexGoodsInfo = this.$store.state.goodsDirectBuyHttpObj
      if (JSON.stringify(vuexGoodsInfo) == '{}') {
        this.$message.error('订单异常，将自动返回商品详情页')
        this.$router.go(-1)
        return false
      }
      this.directBuyHttpObj.sku = vuexGoodsInfo.sku
      const {data: res} = await this.$http.post(createOrderAddViewUrl, vuexGoodsInfo)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.goodsListInfo = res.data
      this.goodsListInfo.item.forEach(items => {
        items.sku = []
        for (let i in items.product_specs) {
          items.sku.push(items.product_specs[i])
        }
        items.sku = items.sku.join('，')
      })
    },
    // 从助力商品详情页进入获取订单数据
    async boostBuyGoodsList(){
      const{data:res} = await this.$http.get(createOrderAddViewByBoostUrl+this.$route.query.boostId,{params:{id:this.$route.query.boostId}})
      console.log(res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.goodsListInfo = res.data
      this.goodsListInfo.item.forEach(items => {
        items.sku = []
        for (let i in items.product_specs) {
          items.sku.push(items.product_specs[i])
        }
        items.sku = items.sku.join('，')
      })
    },

    // 修改选择的地址
    changeAddress(index, addressInfo) {
      this.checkAddress = index
      this.cartOrderHttpObj.addressId = addressInfo.id
      this.directBuyHttpObj.addressId = addressInfo.id
    },
    // 提交订单
    submitOrder() {
      switch (this.pageTyped) {
        case 'cart':
          this.$store.commit('changeCartOrderHttpObjMutations', this.cartOrderHttpObj)
          this.$router.push({path: '/pay', query: {type: 'cart'}})
          break
        case 'goods':
          this.$store.commit('changeGoodsDirectBuyHttpObjMutations', this.directBuyHttpObj)
          this.$router.push({path: '/pay', query: {type: 'goods'}})
          break
        case 'order':
          break
        case 'seckill':
          this.$store.commit('changeGoodsDirectBuyHttpObjMutations', this.directBuyHttpObj)
          this.$router.push({path: '/pay', query: {type: 'seckill'}})
          break
      }
    },

    // 打开选择优惠券弹窗
    toChooseCoupon() {
      if(this.chooseRedEnvelopeInfo.id){
        return this.$message.error('红包和优惠券只能选择一个使用')
      }
      this.chooseCouponDialogVisible = true
    },
    // 获取优惠券列表
    async getCouponList() {
      const {data: res} = await this.$http.get(getMyCouponListUrl, {params: this.myCouponQueryInfo})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.myCouponList = res.data.list
      this.myCouponTotal = res.data.total
      this.myCouponList.forEach(item => {
        // 先把所有优惠券可用状态改为false
        this.$set(item, 'couponStatus', false)
        // 先判断是不是全场优惠券
        if (item.products[0] == 0) {
          // 全场券 如果vuex传来的订单金额大于优惠券使用金额，优惠券状态改为true
          if ((this.goodsListInfo.total_price - this.goodsListInfo.deliver_money) > item.fullMoney) {
            this.$set(item, 'couponStatus', true)
          }
        } else {
          // 部分商品券
          /*
          1.先获取这个商品券可用的商品id
          2.再获取订单的商品id，
          3.取出相同的id，，最后相加，然后判断优惠券可不可用
          */
          let price = 0
          for (let i = 0; i < item.length; i++) {
            for (let j = 0; j < this.goodsListInfo.item; j++) {
              if (item[i] == this.goodsListInfo.item[j].prodcut_id) {
                price += this.goodsListInfo.item[j].total_price
              }
            }
          }
          if (Number(price) > item.fullMoney) {
            this.$set(item, 'counponStatus', true)
          }


          let canUerCouponPrice = 0
          item.products.forEach(couponProductId => {
            if (this.goodsListInfo.item.length > 0) {
              this.goodsListInfo.item.forEach(orderGoodsInfo => {
                if (couponProductId == orderGoodsInfo.product_id) {
                  canUerCouponPrice += orderGoodsInfo.total_price
                }
              })
            }
          })
          if (Number(canUerCouponPrice) > item.fullMoney) {
            this.$set(item, 'couponStatus', true)
          }
        }
      })
    },
    // 提交选择的优惠券
    submitChooseCoupon(couponInfo) {
      if (couponInfo.couponStatus) {
        this.chooseCouponInfo = couponInfo
        this.cartOrderHttpObj.receiveId = couponInfo.id
        this.directBuyHttpObj.receiveId = couponInfo.id
        this.chooseCouponDialogVisible = false
      }
    },
    // 我的优惠券弹窗分页页码显示条数
    myCouponSizeChange(newSzie) {
      // 把每页显示多少数据重新赋值
      this.myCouponQueryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getCouponList()
    },
    // 我的优惠券弹窗页面切换事件
    myCouponCurrentChange(newPage) {
      this.myCouponQueryInfo.page = newPage
      // 重新获取数据并显示
      this.getCouponList()
    },
    // 清除优惠券
    clearCoupon(){
      this.cartOrderHttpObj.receiveId = ''
      this.directBuyHttpObj.receiveId = ''
      this.chooseCouponInfo = {}
    },

    // 获取红包列表
    async getRedEnvelopeList() {
      const {data: res} = await this.$http.get(getMyRedEnvelopeListUrl,{params:{status:0}})
      console.log(res);
      console.log(this.goodsListInfo);
      if (res.code !== 200) return this.$message.error(res.msg)
      let canUseRedEnvelope = []
      if (res.data.list.length > 0) {
        res.data.list.forEach(item => {
          if (item.status === 0) {
            canUseRedEnvelope.push(item)
          }
        });
        canUseRedEnvelope.forEach(item => {
          if (item.products[0] == 0) {
            // 全场券 如果vuex传来的订单金额大于优惠券使用金额，优惠券状态改为true
            if ((this.goodsListInfo.total_price - this.goodsListInfo.deliver_money) > item.full_money) {
              this.redEnvelopeList.push(item)
            }
          } else {
            // 部分商品券
            /*
            1.先获取这个商品券可用的商品id
            2.再获取订单的商品id，
            3.取出相同的id，，最后相加，然后判断优惠券可不可用
            */
            let price = 0
            item.products.forEach(item => {
              this.goodsListInfo.item.forEach(goodsItem => {
                if (item.id == goodsItem.product_id) {
                  price += Number(goodsItem.total_price)
                }
              })
              if (item.full_money > price) {
                this.redEnvelopeList.push(item)
              }
            })
          }
        })
      }
    },
    // 打开红包弹窗
    toChooseRedEnvelope() {
      if(this.chooseCouponInfo.id){
        return this.$message.error('红包和优惠券只能选择一个使用')
      }
      this.chooseRedEnvelopeDialogVisible = true
    },
    // 选择红包
    chooseRedEnvelope(redEnvelopeInfo) {
      this.chooseRedEnvelopeInfo = redEnvelopeInfo
      this.cartOrderHttpObj.envelopesReceiveId = redEnvelopeInfo.id
      this.directBuyHttpObj.envelopesReceiveId = redEnvelopeInfo.id
      console.log(this.chooseRedEnvelopeInfo);
      this.chooseRedEnvelopeDialogVisible = false
    },
    // 清除红包
    clearRedEnvelopes(){
      this.cartOrderHttpObj.envelopesReceiveId = ''
      this.directBuyHttpObj.envelopesReceiveId = ''
      this.chooseRedEnvelopeInfo={}
    },

  },
  computed: {
    orderTotalPrice() {
      if (this.chooseCouponInfo.id) {
        return (this.goodsListInfo.total_price - this.chooseCouponInfo.couponMoney).toFixed(2)
      } else if (this.chooseRedEnvelopeInfo.id) {
        return (this.goodsListInfo.total_price - this.chooseRedEnvelopeInfo.red_envelopes_money).toFixed(2)
      } else {
        return this.goodsListInfo.total_price
      }
    }
  }
}
</script>

<style lang="less" scoped>
    .step-tit {
        padding: 20px 0 10px;
        height: 20px;
        line-height: 20px;
        border-bottom: 1px solid #d2d2d2;

        h3 {
            font-size: 18px;
            color: #555;
        }
        .clear-discount{
            font-size: 12px;
            color: #999999;
            cursor: pointer;
        }
        .option {
            font-size: 12px;
            height: 20px;
            line-height: 20px;
            color: #999999;
        }
    }

    .address-list {
        .address-item {
            position: relative;
            padding: 20px;
            border: 1px #dddddd dashed;
            width: 260px;
            margin-right: 20px;
            font-size: 14px;
            color: #999999;

            .name {
                font-size: 18px;
                color: #555;
            }

            .phone {
                height: 20px;
                line-height: 20px;
                margin-top: 10px;
            }

            .address {
                margin-top: 10px;
                line-height: 20px;
                height: 40px;
                overflow: hidden;
                width: 260px;
            }

            .default {
                position: absolute;
                right: 10px;
                top: 10px;
                color: #EF001C;
            }

            .checked-icon {
                height: 20px;
                width: 20px;
                position: absolute;
                right: -1px;
                bottom: -1px;
                display: none;
                background: url(../../assets/img/dsc-icon.png) 0 -50px no-repeat;
            }
        }

        .address-item.checked {
            border: 1px #EF001C dashed;

            .checked-icon {
                display: block;
            }
        }

    }

    .coupon-box {
        .coupon-content {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            font-size: 14px;

            .coupon-text {
                color: #666666;
            }

            .coupon-info {
                cursor: pointer;
                color: #999999;

                span {
                    color: #EF001C;
                }
            }
        }
    }

    .common {
        font-size: 14px;
        color: #999999;
        margin-right: 30px;
        line-height: 40px;
        margin-left: 20px;

        .total-price {
            font-size: 24px;
            font-weight: 700;
            vertical-align: bottom;
        }
    }

    .coupon-list {
        display: flex;
        justify-content: start;
        flex-flow: wrap row;
        margin-top: 20px;

        .coupon-item {
            background-color: #F0D1A2;
            border-radius: 10px;
            height: 70px;
            padding: 10px;
            position: relative;
            width: 258px;
            margin-right: 20px;
            margin-bottom: 20px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .coupon-bg {
                .coupon-white {
                    background-color: #ffffff;
                    border-radius: 10px;
                    position: absolute;
                    height: 70px;
                    width: 126px;
                    top: 10px;
                    left: 15px;
                    z-index: 1;
                }

                .coupon-circular {
                    background-color: #F0D1A2;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    margin-top: -15px;
                    z-index: 2;
                }
            }

            .coupon-info {
                position: relative;
                z-index: 3;
                display: flex;

                .coupon-value {
                    font-size: 14px;
                    color: #f61f1b;
                    font-weight: bold;
                    line-height: 70px;
                    margin-left: 25px;
                    width: 84px;

                    span {
                        font-size: 22px;
                    }
                }

                .coupon-full-money {
                    margin-left: 36px;
                    color: #462301;

                    .full-money {
                        font-size: 16px;
                        line-height: 30px;
                        margin-top: 10px;
                        font-weight: bold;
                    }

                    .coupon-name {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }

        }

        .coupon-item.not-user {
            background-color: #eeeeee;

            .coupon-circular {
                background-color: #eeeeee;
            }

        }
    }
</style>
